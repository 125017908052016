:root {
  --font-family: "acumin-pro-extra-condensed", sans-serif;

  --bright-red: #AF3120;
  --light-red: #D83F2A;
  --sky-blue: #177ce8;
  --black: #000000;
  --gray: #494949;
  --white: #ffffff;
  --off-white: #f4f4f4;

  --h1-text: 2rem;
  --h2-text: 1.8rem;
  --h3-text: 1.6rem;
  --bold-text: 1.4rem;
  --p-text: 1.3rem;

}

/* ============================ DESKTOP Version ============================ */

.pageTitleReader {
  display: none;
}

#AppContainer {
  max-width: 100vw;
}

/* ________________________________ TopMenu ________________________________ */
#topMenuMenuContainer {
  background-color: var(--off-white);
}

#topMenuBar {
  display: flex;
  flex-direction: row;
  max-width: 100rem;
  width: 100%;
  margin-left: 15rem;
  font-size: 1.1rem;
}

.accessibilityH1 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* -------------------------------------------------------------------------- */

/* _________________________________ NavBar _________________________________ */
#mainContainer-navBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0vw 1.5vh 0vw;
}

.logo-navBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.logo-navBar:hover {
  margin-top: -0.6rem;
  margin-bottom: 0.6rem;
}

#hcdLogo {
  max-width: auto;
  min-width: auto;
}

#droLogo {
  max-width: auto;
  min-width: auto;
}

#container-navBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  max-width: auto;
  margin: 0 2vw;
}

.eachNav-navBar {
  font-size: 1.5rem;
  color: var(--black);
  font-weight: bold;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.eachNav-navBar:hover {
  font-size: 1.6rem;
  margin-top: -0.2rem;
  margin-bottom: 0.2rem;
}

#image-divider {
  width: 100%;
  box-shadow: 0rem 0rem 2rem rgb(94, 94, 94);
}

/* -------------------------------------------------------------------------- */

/* __________________________________ Home __________________________________ */
#redContainerHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: fit-content;
  background-image: linear-gradient(var(--light-red), var(--bright-red));
}

#carouselContainerHome {
  max-height: inherit;
  margin: 3rem 0 0 0;
  max-width: 75%;
  box-shadow: 0.2rem 0.2rem 0.9rem var(--black);
}

#contactAreaHome {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 85vw;
}

#homeContactAreaText {
  display: flex;
  flex-direction: column;
  max-width: 205vh;
  padding: 3rem 2rem;
}

#homeContactAreaText>h2 {
  color: var(--white);
  margin: 0 0 0.7rem 0.8rem;
  font-weight: normal;
  font-size: var(--h2-text);
}

#homeContactAreaText>p {
  color: var(--white);
  margin: 0;
  font-size: var(--p-text);
}

#contactUsButtonHome {
  background-color: var(--bright-red);
  border: 1px solid var(--white);
  color: var(--white);
  padding: 1.5rem 0.5rem;
  margin: 0 2rem;
  min-width: 14%;
  max-width: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  font-size: var(--h3-text);
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

#contactUsButtonHome:hover {
  background-color: var(--off-white);
  color: var(--bright-red);
  box-shadow: 0.1rem 0.1rem 2rem rgb(63, 0, 0);
}

#summaryContainerHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: fit-content;
}

#homeInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90vw;
  margin: 3vh 0;
}

.iconButton {
  cursor: pointer;
  color: var(--bright-red);
  transition: all 200ms ease-in-out;
}

.iconButton:hover {
  color: var(--black);
}

.summary {
  max-width: 26vw;
  margin: 1rem 1rem;
}

.summary>i {
  color: var(--bright-red);
}

.summary>h3 {
  font-size: var(--h3-text);
  font-weight: bold;
}

.summary>p {
  color: var(--gray);
  font-size: var(--p-text);
}

.homeLink {
  font-size: var(--p-text);
  color: var(--bright-red);
}

.homeLink:hover {
  color: var(--gray);
}

#testimonialsSection {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 250px;
  max-width: 75%;
  width: 95%;
}

#testimonialsTopPart {
  display: flex;
  flex-direction: column;
}

#testimonialsTopPart>h3 {
  font-size: var(--h3-text);
  font-weight: bold;
}

#testimonialsTopPart>div {
  border-style: solid;
  border-width: 0.5px;
  border-color: lightgray;
  margin: 6px 0 6px 3px;
}

#testimonialsName {
  padding: 0.7rem 0 0 1rem;
}

#testimonialsQuoteBox {
  border-radius: 5px;
  background-color: var(--off-white);
  min-height: 50px;
  font-size: var(--p-text);
}

#testimonialsQuote {
  max-width: 100%;
  padding: 1rem;
}

#testimonialsCarouselStyle {
  width: 100%;
}

#homeWhiteSpace {
  height: 7vh;
}

/* -------------------------------------------------------------------------- */

/* ________________________________ About Us ________________________________ */
.mainContainerAboutUs {
  max-width: 1700px;
  margin: auto;
  width: 90%;
}

.titleAboutUs {
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: var(--h2-text);
}

.containerAboutUs {
  display: flex;
  flex-direction: row;
  align-items: space-between;
}

.leftHalfAboutUs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.rightHalfAboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.subTitleAboutUs {
  padding-top: 5px;
  font-weight: bold;
  font-size: var(--h3-text);
}

.officeTitle {
  display: none;
}

.subTextContainerAboutUs {
  padding-left: 20px;
}

.officeText {
  max-width: 35vw;
  border-left: solid;
  border-color: #bdbdbd;
  border-width: 0.1vh;
  border-radius: 0.3vw;
}

.subTextAboutUs {
  color: #515151;
  font-weight: normal;
  font-size: var(--p-text);
}

.contactsAboutUs {
  padding-left: 20px;
  font-size: var(--p-text);
}

#photoAboutUs {
  max-width: 100%;
  min-width: 100%;
  margin: 2.2rem 0 1.6rem 2.2rem;
  box-shadow: 0.3rem 0.3rem 0.7rem rgb(132, 132, 132);
}

.whiteSpaceAboutUs {
  height: 6vh;
}

/* -------------------------------------------------------------------------- */

/* _____________________________ Our Providers ______________________________ */
#mainContainerOurProviders {
  max-width: 1700px;
  margin: auto;
  width: 90%;
}

#mainContainerOurProviders>h2 {
  font-size: var(--h2-text);
  font-weight: bold;
  padding-top: 5vh;
}

#profileSectionOurProviders {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 6vw 1vh 6vw;
}

#eachProfileOurProviders {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem 0.4rem 0 0.4rem;
}

#eachProfileOurProviders>img {
  height: 100%;
  width: 100%;
}

#eachProfileOurProviders>h3 {
  display: block;
  font-size: var(--bold-text);
  text-transform: uppercase;
  width: 100%;
  color: var(--bright-red);
  padding: 0;
}

#eachProfileOurProviders>p {
  font-size: 1.05rem;
  width: 100%;
  padding: 0 0.1rem;
}

#ourProvidersInsideName {
  font-size: var(--h1-text);
  margin: 1rem 0;
  padding-left: 1rem;
}

#ourProviderContent {
  display: flex;
  flex-flow: row wrap;
  margin: 0 2rem 2rem 2rem;
}

#ourProvidersInsideImage {
  float: left;
  height: 100%;
  width: 9vw;
}

#ourProvidersInsideBio {
  display: block;
  float: right;
  font-size: var(--p-text);
  margin: 0.1rem 0 0 1.5rem;
  height: 100%;
  max-height: 40vh;
  width: 60%;
  line-height: 2rem;
}

#ourProvidersInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ourProvidersInfoTitle {
  font-size: 1.6rem;
  font-weight: bold;
}

#ourProvidersEduContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.ourProvidersEdu {
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}

#ourProvidersIntContainer {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 2rem;
}

.ourProvidersInt {
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}

#ourProvidersBottomSection {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-height: auto;
}

#ourProvidersIhapSection {
  display: flex;
  flex-flow: row wrap;
  padding: 1.5rem;
  height: 40vh;
}

#ourProvidersIhap {
  overflow: scroll;
  padding: 0.8rem 0 0 0rem;
  min-width: 17rem;
  width: 100%;
  border: solid .5px;
  height: 100%;
}

#ourProvidersState {
  font-size: 1.4rem;
  padding-left: 1rem;
}

#stateProvider {
  padding-left: 1rem;
}

#ourProvidersWhiteSpace {
  height: 7vh;
}

/* -------------------------------------------------------------------------- */

/* ______________________________ Our Services ______________________________ */
#mainCharacterOurServices {
  max-width: 1700px;
  margin: auto;
  width: 90%;
}

.servicesTitle {
  font-size: var(--h2-text);
  font-weight: bold;
  padding: 2vh;
}

.servicesSubText {
  color: var(--black);
  font-weight: normal;
  font-size: var(--p-text);
  padding-left: 1.5rem;
}

.servicesPointsTitle {
  font-size: 1.3rem;
  padding-left: 2.5rem;
}

#servicesPointsContainer {
  column-count: 3;
  padding-left: 7rem;
}

.servicesPointText {
  color: var(--black);
  font-weight: normal;
  font-size: var(--p-text);
}

#insuranceListContainer {
  display: flex;
  justify-content: center;
}

#insuranceList {
  overflow: scroll;
  min-width: 17rem;
  width: 90%;
  border: solid .5px;
  max-height: 30vh;
}

.insurancePlan {
  font-size: var(--p-text);
  margin-left: 0.5rem;
  padding-bottom: 0.8rem;
}

.subInsurancePlan {
  font-size: var(--p-text);
  margin-left: 2rem;
  padding-bottom: 0.8rem;
  list-style-type: circle;
}

#servicesSubTextContainer {
  float: left;
}

#ourServicesWeight {
  float: right;
  max-width: 25rem;
  margin: 0 3rem;
}

#weightServicesImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 100%;
}

#weightLossButton {
  font-size: var(--bold-text);
  color: var(--bright-red);
  margin-left: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border: solid;
  border-radius: 1rem;
  transition: all 200ms ease-in-out;
}

#weightLossButton:hover {
  color: #000000;
  border-color: var(--light-red);
  margin-left: 1.5rem;
}

#ourServicesHormone {
  display: block;
  column-count: 4;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  max-width: 90%;
}

.qrCode {
  width: 90%;
  padding-bottom: 3rem;
}

#servicesWhiteSpace {
  height: 7vh;
}

/* -------------------------------------------------------------------------- */

/* ______________________________ Weight Loss _______________________________ */
#mainCharacterWeightLoss {
  max-width: 1700px;
  margin: auto;
  width: 90%;
}

.weightTitle {
  font-size: var(--h2-text);
  font-weight: bold;
  padding-top: 4.5vh;
}

#weightTopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

#weightBeforeAfterSection {
  max-height: 100%;
}

.beforeAfterTitle {
  font-size: 1.4rem;
  margin-left: 0.8rem;
}

#weightImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 40%;
}

.beforeAfterContainer {
  font-size: var(--p-text);
  margin-right: 0.4rem;
}

.beforeAfterPhoto {
  border-radius: 0.2rem;
  max-height: 90%;
  box-shadow: 0.2rem 0.3rem 0.9rem black;
}

.beforeAfterText {
  font-size: var(--p-text);
  margin: 0.6rem 1rem;
}

.weightSubText {
  color: var(--black);
  font-weight: normal;
  font-size: var(--p-text);
  padding-left: 1.5rem;
}

.listItemText {
  font-size: var(--p-text);
  margin-left: 1.5rem;
  padding-bottom: 0.6rem;
}

.weightSubListText {
  font-size: var(--p-text);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-left: 3rem;
  list-style-type: circle;
}

.columnList {
  column-count: 3;
}

.phoneButton {
  display: none;
}

.weightContactText {
  font-size: var(--h2-text);
}

#weightContactButton {
  color: var(--bright-red);
  transition: all 200ms ease-in-out;
}

#weightContactButton:hover {
  color: black;
  cursor: pointer;
}

#weightWhiteSpace {
  height: 7vh;
}

/* -------------------------------------------------------------------------- */

/* ___________________________ Our Privacy Policy ___________________________ */
#mainContainerPrivacy {
  max-width: 1700px;
  margin: auto;
  width: 90%;
}

#mainContainerPrivacy>h2 {
  font-weight: bold;
  font-size: 1.8rem;
}

#mainContainerPrivacy>.top {
  padding-top: 4vh;
}

#mainContainerPrivacy>p {
  padding-left: 1.4rem;
  color: var(--gray);
  font-weight: normal;
  font-size: 1.2rem;
}

#mainContainerPrivacy>.policy {
  padding-left: 2.8rem;
  color: var(--gray);
  font-weight: normal;
  font-size: 1.2rem;
}

#mainContainerPrivacy>.address {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 1rem 0;
  font-weight: bold;
}

#privacyWhiteSpace {
  height: 8vh;
}

/* -------------------------------------------------------------------------- */

/* _______________________________ Contact Us _______________________________ */
#mainContainerContactUs {
  max-width: 1700px;
  margin: auto;
  width: 90%;
}

#mapInfoContactUs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 6rem 26rem;
  grid-template-areas:
    "phone fax"
    "map contactField";
  margin: 5vh 0;
}

.infoContactUs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.infoContactUs>.icon {
  color: var(--bright-red);
}

.infoContactUs>p {
  font-weight: normal;
  font-size: 1.5rem;
}

.phoneInfo {
  grid-area: phone;
}

.faxInfo {
  grid-area: fax;
}

/* #infoAndPhotoContactUs > img {
  display: none;
  padding: 1rem 0 0 11rem;
  width: 100%;
} */

#mapInfoContactUs>iframe {
  grid-area: map;
  display: flex;
  justify-self: center;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: #5A7584;
  box-shadow: 0.1rem 0.1rem #d4d4d4;
  min-width: 90%;
  max-width: 96%;
  height: 100%;
}

#contactFieldPlacement {
  grid-area: contactField;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
}

#profileContainerContactUs {
  display: block;
  column-count: 4;
  margin: auto;
  padding-top: 2rem;
  max-width: 90%;
}

#contactUsProfile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2rem;
}

#contactUsContact {
  display: flex;
  flex-direction: column;
}

#contactUsNames {
  font-size: 1.2rem;
  padding-left: 0.4rem;
  margin-bottom: 0;
  max-width: 20vw;
}

#contactUsDividerProfile {
  border-style: solid;
  border-width: 1px;
  border-color: #e4e4e4;
  margin: 0.5rem 0 0.5rem 0.1rem;
}

#contactUsInfoBtn {
  font-size: 1rem;
  font-weight: bold;
  color: var(--bright-red);
  padding-left: 1rem;
  transition: all 200ms ease-in-out;
}

#contactUsInfoBtn:hover {
  color: black;
  cursor: pointer;
}

#modalContainer {
  width: auto;
  padding: 1vh 1vw;
}

#contactUsInsideInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 60rem;
  max-width: 100%;
}

.contactUsProviderInfo {
  font-size: 1.6rem;
  max-width: 100%;
  margin-left: 5rem;
}

#mainContainerContactUs>h2 {
  font-size: 1.7rem;
}

#contactUsSubText {
  padding-left: 2.8rem;
}

#contactUsPhoto {
  float: right;
  max-height: 30rem;
  margin: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0.4rem 0.4rem 1.5rem rgb(75, 75, 75);
}

#contactUsSubText>p {
  color: #515151;
  font-weight: normal;
  font-size: 1.2rem;
  padding-left: 1.4rem;
}

#contactUsSubText>.directions {
  padding-left: 2.8rem;
}

#contactUsWhiteSpace {
  height: 7vh;
}

/* -------------------------------------------------------------------------- */

/* _____________________________ Contact Field _____________________________ */
#contactFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  border-style: solid;
  border-color: #cccccc;
  border-width: 0.1rem;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem #cccccc;
}

.status {
  display: flex;
  flex-wrap: row wrap;
  justify-content: center;
  font-size: 1.5rem;
  margin: 1vh 0 2vh 0;
}

.contactField {
  margin: 0.9rem 0;
  font-size: 1.3rem;
}

.label {
  margin: 0 0.4rem 0.4rem 0;
}

.input {
  padding: 0.4rem 1rem 0.4rem 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.4rem;
}

.message {
  margin-top: 0.4rem;
  align-self: center;
  padding: 0.2rem 0.7rem;
  width: 90%;
  border-width: 0.1rem;
  border-color: #000000;
}

.submit {
  align-self: center;
}

#submitBtn {
  border-radius: 0.6rem;
  box-shadow: 0.1rem 0.1rem lightgray;
  font-size: 1.4rem;
  padding: 0.4rem 1.5rem;
}

.contactFieldGroups {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100%;
}

/* -------------------------------------------------------------------------- */

/* _________________________________ Footer _________________________________ */
#footerContainer {
  background-color: #1C1C1C;
  height: 100px;
  width: 100%;
  bottom: 0;
}

#footerMainContainer {
  max-width: 1250px;
  margin: auto;
}

#footerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#footerCopyright {
  font-size: 17px;
  padding: 25px 0 0 30px;
  color: #a1a1a1;
}

#footerLinkCopyright {
  font-size: 17px;
}

#footerNavContainer {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#footerNav {
  margin-right: 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.footerEachNav {
  font-size: 17px;
  color: var(--white);
}

/* -------------------------------------------------------------------------- */
#bottomMenuMainContainer {
  display: none;
}

/* ========================================================================== */



/* ============================= TABLET Version ============================= */
@media (min-width: 465px) and (max-width: 820px) {
  #AppContainer {
    max-width: 100%;
  }

  /* ________________________________ TopMenu ________________________________ */
  #topMenuBar {
    display: flex;
    justify-content: flex-start;
    max-width: 10rem;
    margin: 0;
    font-size: 1rem;
  }

  /* -------------------------------------------------------------------------- */

  /* _________________________________ NavBar _________________________________ */
  #mainContainer-navBar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
  }

  .logo-navBar {
    max-width: auto;
    min-width: auto;
  }

  #container-navBar {
    display: none;
  }

  .eachNav-navBar {
    font-size: 0.9em;
    color: var(--black);
    font-weight: bold;
  }

  #imageDivider {
    width: 100%;
  }

  /* -------------------------------------------------------------------------- */

  /* __________________________________ Home __________________________________ */
  #redContainerHome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: fit-content;
    background-image: linear-gradient(var(--light-red), var(--bright-red));
  }

  #carouselContainerHome {
    max-height: inherit;
    margin-top: 2vh;
    max-width: 95%;
  }

  #contactAreaHome {
    display: flex;
    flex-direction: row;
    max-width: 97%;
  }

  #homeContactAreaText {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 2rem 1rem;
  }

  #homeContactAreaText>h2 {
    color: var(--white);
    margin: 0 0 0.7rem 0.8rem;
    font-weight: normal;
    font-size: 2rem;
  }

  #homeContactAreaText>p {
    color: var(--white);
    margin: 0;
    font-weight: normal;
    font-size: 1.1rem;
  }

  #contactUsButtonHome {
    background-color: var(--bright-red);
    border: 1px solid var(--white);
    color: var(--white);
    padding: 1.5rem 0.5rem;
    margin: 0 2rem;
    min-width: 18%;
    max-width: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;
  }

  #contactUsButtonHome:hover {
    background-color: var(--off-white);
    color: var(--bright-red);
  }

  #summaryContainerHome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: fit-content;
  }

  #homeInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 1.5vh 0;
  }

  .summary {
    max-width: 45%;
    margin: 0.5rem 0.9rem;
  }

  .summary>i {
    color: var(--bright-red);
  }

  .summary>h3 {
    font-size: 1.7rem;
    font-weight: bold;
  }

  .summary>p {
    color: var(--gray);
    font-size: 1.3rem;
  }

  .homeLink {
    font-size: 1.3rem;
    color: var(--bright-red);
  }

  .homeLink:hover {
    color: var(--gray);
  }

  #testimonialsSection {
    display: block;
    min-width: 93%;
  }

  #testimonialsTopPart {
    display: flex;
    flex-direction: column;
  }

  #testimonialsTopPart>h3 {
    font-size: 1.7rem;
    font-weight: bold;
  }

  #testimonialsTopPart>div {
    border-style: solid;
    border-width: 0.5px;
    border-color: lightgray;
    margin: 6px 0 6px 3px;
  }

  #testimonialsName {
    padding: 0.7rem 0 0 1rem;
  }

  #testimonialsQuoteBox {
    border-radius: 5px;
    background-color: var(--off-white);
    min-height: 1.8vh;
    font-size: 1.2rem;
  }

  #testimonialsQuote {
    max-width: 100%;
    padding: 1rem;
  }

  #homeWhiteSpace {
    height: 10vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ________________________________ About Us ________________________________ */
  .mainContainerAboutUs {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  .titleAboutUs {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 1.8rem;
  }

  .containerAboutUs {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 10px 0 10px;
    float: left;
  }

  .leftHalfAboutUs {
    display: flex;
    flex-direction: column;
    float: left;
  }

  .rightHalfAboutUs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
  }

  .subTitleAboutUs {
    padding-top: 5px;
    font-weight: bold;
    font-size: 1.1rem;
    float: left;
  }

  .officeTitle {
    display: none;
  }

  .subTextContainerAboutUs {
    padding-left: 1rem;
  }

  .officeText {
    flex: 1;
    border-left: solid;
    border-color: #bdbdbd;
    border-width: 0.1vh;
    border-radius: 0.3vw;
  }

  .subTextAboutUs {
    color: #515151;
    font-weight: normal;
    font-size: 1.2rem;
  }

  .contactsAboutUs {
    padding-left: 10px;
    font-size: 1.2rem;
  }

  #photoAboutUs {
    flex: 0.6;
    margin: 1.5rem 0 1.5rem 0px;
    max-width: 100%;
    min-width: 65%;
    box-shadow: 0.3rem 0.3rem 0.7rem rgb(132, 132, 132);
  }

  .whiteSpaceAboutUs {
    height: 12vh;
  }

  /* -------------------------------------------------------------------------- */

  /* _____________________________ Our Providers _____________________________ */
  #mainContainerOurProviders {
    max-width: 100%;
    margin: auto;
    width: 90%;
  }

  #mainContainerOurProviders>h2 {
    font-weight: bold;
    padding-top: 4vh;
  }

  #profileSectionOurProviders {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    width: 100%;
    padding-bottom: 0vh;
  }

  #eachProfileOurProviders {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding: 1rem 0.2rem 0 0.2rem;
    padding-bottom: 1vh;
    height: 100%;
  }

  #eachProfileOurProviders>img {
    height: 100%;
    width: 100%;
  }

  #eachProfileOurProviders>h3 {
    display: block;
    font-size: 1.15rem;
    text-transform: uppercase;
    width: 100%;
    color: var(--bright-red);
    padding: 0;
  }

  #eachProfileOurProviders>p {
    font-size: 1.05rem;
    width: 100%;
    padding: 0 0.1rem;
  }

  #ourProvidersInsideName {
    font-size: 1.9rem;
    margin: 1rem 0;
    padding-left: 1rem;
  }

  #ourProviderContent {
    display: flex;
    flex-flow: row wrap;
    margin: 0 1rem 1rem 1rem;
  }

  #ourProvidersInsideImage {
    float: left;
    height: 100%;
    width: 25%;
  }

  #ourProvidersInsideBio {
    display: block;
    float: right;
    font-size: 1.2rem;
    margin: 0.1rem 0 0 1.4rem;
    height: 100%;
    max-height: 31vh;
    width: 63%;
    line-height: 1.7rem;
  }

  #ourProvidersInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .ourProvidersInfoTitle {
    font-size: 1.3rem;
    font-weight: bold;
  }

  #ourProvidersEduContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .ourProvidersEdu {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
    line-height: 1.6rem;
  }

  #ourProvidersIntContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 1rem;
  }

  .ourProvidersInt {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
    line-height: 1.5rem;
  }

  #ourProvidersBottomSection {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-height: auto;
  }

  #ourProvidersIhapSection {
    display: flex;
    flex-flow: row wrap;
    padding: 1.5rem;
    height: 30vh;
  }

  #ourProvidersIhap {
    overflow: scroll;
    padding: 0.8rem 0 0 0rem;
    min-width: 17rem;
    width: 100%;
    border: solid .5px;
    height: 95%;
  }

  #ourProvidersState {
    font-size: 1.4rem;
    padding-left: 1rem;
  }

  #stateProvider {
    padding-left: 1rem;
  }

  #ourProvidersWhiteSpace {
    height: 7vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ______________________________ Our Services ______________________________ */
  #mainCharacterOurServices {
    max-width: 1700px;
    margin: auto;
    width: 95%;
  }

  .servicesTitle {
    font-size: var(--h2-text);
    font-weight: bold;
    padding-top: 4vh;
  }

  .servicesSubText {
    color: var(--black);
    font-weight: normal;
    font-size: 1.2rem;
    padding-left: 1rem;
  }

  .servicesPointsTitle {
    font-size: 1.2rem;
    padding-left: 2rem;
  }

  #servicesPointsContainer {
    column-count: 3;
    padding-left: 4.5rem;
  }

  .servicesPointText {
    color: var(--black);
    font-weight: normal;
    font-size: 1.2rem;
  }

  #insuranceListContainer {
    display: flex;
    justify-content: center;
  }

  #insuranceList {
    overflow: scroll;
    min-width: 17rem;
    width: 90%;
    border: solid .5px;
    max-height: 30vh;
  }

  .insurancePlan {
    font-size: 1.2rem;
    margin-left: 0.5rem;
    padding-bottom: 0.8rem;
  }

  .subInsurancePlan {
    font-size: 1.2rem;
    margin-left: 1.5rem;
    padding-bottom: 0.8rem;
    list-style-type: circle;
  }

  #ourServicesWeight {
    float: right;
    max-width: 20rem;
    margin: 0 3rem;
  }

  #weightServicesImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 100%;
  }

  #weightLossButton {
    font-size: 1.2rem;
    color: var(--bright-red);
    margin-left: 1.5rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;
  }

  #weightLossButton:hover {
    color: #000000;
  }

  #ourServicesHormone {
    display: block;
    column-count: 2;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    max-width: 80%;
  }

  #servicesWhiteSpace {
    height: 8vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ______________________________ Weight Loss _______________________________ */
  #mainCharacterWeightLoss {
    max-width: 765px;
    margin: auto;
    width: 95%;
  }

  .weightTitle {
    font-size: var(--h2-text);
    font-weight: bold;
    padding-top: 4.5vh;
  }

  #weightTopSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  #weightBeforeAfterSection {
    margin-bottom: 1rem;
  }

  .beforeAfterTitle {
    font-size: 1.4rem;
  }

  #weightImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 50%;
    border-left: solid;
    border-color: #bdbdbd;
    border-width: 0.1vh;
    border-radius: 0.3vw;
  }

  .beforeAfterPhoto {
    border-radius: 0.2rem;
    max-height: 60vh;
    min-height: 25vh;
    box-shadow: 0.2rem 0.3rem 0.9rem black;
  }

  .beforeAfterText {
    font-size: var(--p-text);
    margin: 0.6rem 1rem;
  }

  .weightSubText {
    color: var(--black);
    font-weight: normal;
    font-size: 1.2rem;
    padding-left: 1rem;
  }

  .listItemText {
    font-size: 1.2rem;
    margin-left: 1rem;
    padding-bottom: 0.6rem;
  }

  .weightSubListText {
    font-size: 1.2rem;
    margin-left: 1.5rem;
    padding-bottom: 0.6rem;
    margin-left: 3rem;
    list-style-type: circle;
  }

  .columnList {
    column-count: 3;
  }

  #weightWhiteSpace {
    height: 7vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ___________________________ Our Privacy Policy ___________________________ */
  #mainContainerPrivacy {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  #mainContainerPrivacy>h2 {
    font-weight: bold;
    font-size: 1.7rem;
  }

  #mainContainerPrivacy>.top {
    padding-top: 4vh;
  }

  #mainContainerPrivacy>p {
    padding-left: 1.4rem;
    color: var(--gray);
    font-weight: normal;
    font-size: 1.2rem;
  }

  #mainContainerPrivacy>.policy {
    padding-left: 2.8rem;
    color: var(--gray);
    font-weight: normal;
    font-size: 1.2rem;
  }

  #mainContainerPrivacy>.address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 1rem 0;
    font-weight: bold;
  }

  #privacyWhiteSpace {
    height: 10vh;
  }

  /* -------------------------------------------------------------------------- */

  /* _______________________________ Contact Us _______________________________ */
  #mainContainerContactUs {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  #mapInfoContactUs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 6rem 26rem;
    grid-template-areas:
      "phone fax"
      "map contactField";
    margin: 5vh 0;
  }

  .infoContactUs {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .infoContactUs>.icon {
    color: var(--bright-red);
  }

  .infoContactUs>p {
    font-weight: normal;
    font-size: 1.3rem;
  }

  .phoneInfo {
    grid-area: phone;
  }

  .faxInfo {
    grid-area: fax;
  }

  /* #infoAndPhotoContactUs > img {
  display: none;
  padding: 1rem 0 0 11rem;
  width: 100%;
} */

  #mapInfoContactUs>iframe {
    grid-area: map;
    display: flex;
    justify-self: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: #5A7584;
    box-shadow: 0.1rem 0.1rem #d4d4d4;
    min-width: 90%;
    max-width: 96%;
    height: 100%;
  }

  #contactFieldPlacement {
    grid-area: contactField;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  #profileContainerContactUs {
    display: block;
    column-count: 2;
    padding-top: 1.5rem;
    max-width: 90%;
  }

  #contactUsProfile {
    display: flex;
    flex-direction: row;
    padding-bottom: 1.5rem;
  }

  #contactUsContact {
    display: flex;
    flex-direction: column;
  }

  #contactUsNames {
    font-size: 1.1rem;
    padding-left: 0.4rem;
    margin-bottom: 0;
    max-width: 20vw;
  }

  #contactUsDividerProfile {
    border-style: solid;
    border-width: 1px;
    border-color: #e4e4e4;
    margin: 0.5rem 0 0.5rem 0.1rem;
  }

  #contactUsInfoBtn {
    font-size: 1rem;
    font-weight: bold;
    color: var(--bright-red);
    padding-left: 1rem;
  }

  #modalContainer {
    width: 90%;
  }

  #contactUsInsideInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 90%;
    width: 100%;
  }

  .contactUsProviderInfo {
    font-size: 1.8rem;
    max-width: 100%;
    margin-left: 5rem;
  }

  .modalGroup {
    margin-bottom: 9rem;
  }

  #mainContainerContactUs>h2 {
    font-size: 1.6rem;
  }

  #contactUsPhoto {
    float: right;
    max-width: 50%;
    max-height: 27rem;
    margin: 1.5rem;
    border-radius: 0.8rem;
    box-shadow: 0.4rem 0.4rem 1.5rem rgb(75, 75, 75);
  }

  #contactUsSubText {

    text-align: justify;
    padding-left: 1.6rem;
  }

  #contactUsSubText>p {
    color: #515151;
    font-weight: normal;
    font-size: 1.2rem;
  }

  #contactUsSubText>.directions {
    padding-left: 0.8rem;
  }

  #contactUsWhiteSpace {
    height: 12vh;
  }

  /* -------------------------------------------------------------------------- */

  /* _____________________________ Contact Field _____________________________ */
  #contactFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-style: solid;
    border-color: #cccccc;
    border-width: 0.1rem;
    border-radius: 1rem;
    box-shadow: 0.1rem 0.1rem #cccccc;
  }

  .status {
    display: flex;
    flex-wrap: row wrap;
    justify-content: center;
    font-size: 1.1rem;
    margin: 1vh 0 2vh 0;
  }

  .contactField {
    margin: 0.5rem 0;
    font-size: 1.2rem;
  }

  .label {
    margin-right: 0.8rem;
  }

  .input {
    padding: 0.3rem 3rem 0.3rem 0.4rem;
    margin-right: 0.5rem;
    border-radius: 0.4rem;
  }

  .message {
    align-self: center;
    width: 95%;
  }

  .submit {
    align-self: center;
  }

  #submitBtn {
    background-color: var(--off-white);
    color: black;
    border-color: black;
    border-radius: 0.6rem;
    box-shadow: 0.1rem 0.1rem lightgray;
    font-size: 1.5rem;
    padding: 0.4rem 1.7rem;
  }

  .contactFieldGroups {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 100%;
  }

  /* -------------------------------------------------------------------------- */

  /* _________________________________ Footer _________________________________ */
  #footerContainer {
    display: none;
    background-color: #1C1C1C;
    height: 100px;
    width: 100%;
    bottom: 0;
  }

  #footerMainContainer {
    max-width: 1250px;
    margin: auto;
  }

  #footerBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #footerCopyright {
    font-size: 14px;
    padding: 15px 0 0 15px;
    color: #a1a1a1;
  }

  #footerLinkCopyright {
    font-size: 14px;
  }

  #footerNavContainer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
  }

  #footerNav {
    margin-right: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .footerEachNav {
    font-size: 14px;
    color: var(--white);
  }

  /* -------------------------------------------------------------------------- */
  #bottomMenuMainContainer {
    background-color: var(--white);
    box-shadow: 5px 6px 10px rgb(30, 30, 30, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: stretch;
    position: fixed;
    bottom: 0;
    z-index: 5;
    height: 60px;
    width: 100%;
  }

  .iconContainers {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
  }

  .iconContainers:not(:last-child) {
    height: 100%;
    border-right: 1px solid #c6c6c6;
  }

  .icons {
    color: var(--black);
  }

  .bottomMenuText {
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    width: 80px;
    margin-left: -30px;
    line-height: 17px;
  }


}

/* ========================================================================== */



/* ============================= MOBILE VERSION ============================= */
@media (max-width: 465px) {

  /* ________________________________ TopMenu ________________________________ */
  #topMenuMenuContainer {
    display: none;
  }

  #topMenuBar {
    max-width: 426px;
  }

  /* -------------------------------------------------------------------------- */

  /* _________________________________ NavBar _________________________________ */
  #mainContainer-navBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vh 0;
  }

  .logo-navBar {
    max-width: auto;
    min-width: auto;
  }

  #container-navBar {
    display: none;
  }

  .eachNav-navBar {
    font-size: 0.9em;
    color: var(--black);
    font-weight: bold;
  }

  #imageDivider {
    width: 100%;
  }

  /* -------------------------------------------------------------------------- */

  /* __________________________________ Home __________________________________ */
  #redContainerHome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: fit-content;
    background-image: linear-gradient(var(--light-red), var(--bright-red));
  }

  #carouselContainerHome {
    max-height: inherit;
    margin-top: 2vh;
    max-width: 95%;
  }

  #contactAreaHome {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  #homeContactAreaText {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 1vh;
  }

  #homeContactAreaText>h2 {
    color: var(--white);
    margin: 0 0 0.7rem 0.3rem;
    font-weight: normal;
    font-size: 1.5rem;
  }

  #homeContactAreaText>p {
    color: var(--white);
    margin: 0.2rem;
    font-weight: normal;
    font-size: 0.8rem;
  }

  #contactUsButtonHome {
    background-color: var(--bright-red);
    border: 1px solid var(--white);
    color: var(--white);
    padding: 1rem 0.5rem;
    margin: 0 0 0.9rem 0;
    min-width: 14%;
    max-width: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;
  }

  #contactUsButtonHome:hover {
    background-color: var(--off-white);
    color: var(--bright-red);
  }

  #summaryContainerHome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: fit-content;
  }

  #homeInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 2vh 0;
  }

  .summary {
    max-width: 100%;
    margin: 0.5rem 0.7rem;
  }

  .summary>i {
    color: var(--bright-red);
  }

  .summary>h3 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .summary>p {
    color: var(--gray);
    font-size: 1.2rem;
  }

  .homeLink {
    font-size: 1.2rem;
    color: var(--bright-red);
  }

  .homeLink:hover {
    color: var(--gray);
  }

  #testimonialsSection {
    display: block;
    min-width: 90%;
  }

  #testimonialsTopPart {
    display: flex;
    flex-direction: column;
  }

  #testimonialsTopPart>h3 {
    font-size: 1.7rem;
    font-weight: bold;
  }

  #testimonialsTopPart>div {
    border-style: solid;
    border-width: 0.5px;
    border-color: lightgray;
    margin: 0;
  }

  #testimonialsName {
    padding: 0.5rem 0 0 1rem;
  }

  #testimonialsQuoteBox {
    border-radius: 5px;
    background-color: var(--off-white);
    min-height: 1.5vh;
    font-size: 1.2rem;
  }

  #testimonialsQuote {
    width: 100%;
    padding: 1rem;
  }

  #homeWhiteSpace {
    height: 20vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ________________________________ About Us ________________________________ */
  .mainContainerAboutUs {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  .titleAboutUs {
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    text-align: center;
    font-size: 1.8rem;
  }

  .containerAboutUs {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 5px 0 5px;
    float: left;
  }

  .leftHalfAboutUs {
    display: flex;
    flex-direction: column;
    float: left;
  }

  .rightHalfAboutUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  .subTitleAboutUs {
    padding-top: 5px;
    font-weight: bold;
    font-size: 1.6rem;
    float: left;
  }

  .officeTitle {
    display: none;
  }

  .subTextContainerAboutUs {
    padding-left: 5px;
  }

  .officeText {
    max-width: 100vw;
    padding-left: 5px;
  }

  .subTextAboutUs {
    color: #515151;
    font-weight: normal;
    font-size: 1.2rem;
  }

  .contactsAboutUs {
    padding-left: 5px;
    font-size: 1.2rem;
  }

  #photoAboutUs {
    flex: 0.6;
    margin: 1rem 0 1.1rem 0px;
    max-height: 19rem;
    max-width: 100%;
    min-width: 65%;
    box-shadow: 0.3rem 0.3rem 0.7rem rgb(132, 132, 132);
  }

  .whiteSpaceAboutUs {
    height: 13vh;
  }

  /* -------------------------------------------------------------------------- */

  /* _____________________________ Our Providers _____________________________ */
  #mainContainerOurProviders {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  #mainContainerOurProviders>h2 {
    font-weight: bold;
    padding-top: 5vh;
    font-size: 1.4rem;
  }

  #profileSectionOurProviders {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    width: 100%;
    padding-bottom: 1vh;
  }

  #eachProfileOurProviders {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 1rem 0.2rem 0 0.2rem;
    height: 100%;
  }

  #eachProfileOurProviders>img {
    height: 100%;
    width: 100%;
  }

  #eachProfileOurProviders>h3 {
    display: block;
    font-size: 1.1rem;
    text-transform: uppercase;
    width: 100%;
    color: var(--bright-red);
    padding: 0;
  }

  #eachProfileOurProviders>p {
    font-size: 1rem;
    width: 100%;
    padding: 0 0.1rem;
  }

  #ourProvidersInsideName {
    font-size: 1.8rem;
    margin: 1rem 0;
    padding-left: 1rem;
  }

  #ourProviderContent {
    display: flex;
    flex-flow: column;
    margin: 0 1rem 1rem 1rem;
  }

  #ourProvidersInsideImage {
    display: none;
    float: none;
    height: 100%;
    width: 25%;
  }

  #ourProvidersInsideBio {
    display: block;
    float: none;
    font-size: 17px;
    margin: 0.1rem 0 0 0.1rem;
    height: 100%;
    max-height: 50vh;
    width: 100%;
    line-height: 1.5rem;
  }

  #ourProvidersInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ourProvidersInfoTitle {
    font-size: 1.4rem;
    font-weight: bold;
    padding-top: 0.7rem;
  }

  #ourProvidersEduContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
  }

  .ourProvidersEdu {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;

  }

  #ourProvidersIntContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.1rem;
    margin-bottom: 1rem;
  }

  .ourProvidersInt {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
  }

  #ourProvidersBottomSection {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-height: auto;
  }

  #ourProvidersIhapSection {
    display: flex;
    flex-flow: row wrap;
    padding: 0.6rem;
    height: 35vh;
  }

  #ourProvidersIhap {
    overflow: scroll;
    padding: 0.8rem 0 0 0rem;
    min-width: 17rem;
    width: 100%;
    border: solid .5px;
    height: 95%;
  }

  #ourProvidersState {
    font-size: 1.4rem;
    padding-left: 1rem;
  }

  #stateProvider {
    padding-left: 1rem;
  }

  #ourProvidersWhiteSpace {
    height: 7vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ______________________________ Our Services ______________________________ */
  #mainCharacterOurServices {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  .servicesTitle {
    font-size: var(--h3-text);
    font-weight: bold;
    padding-top: 4vh;
  }

  .servicesSubText {
    color: var(--black);
    font-weight: normal;
    font-size: 1.2rem;
    padding-left: 0.5rem;
  }

  .servicesPointsTitle {
    font-size: 1.2rem;
    padding-left: 1rem;
  }

  #servicesPointsContainer {
    column-count: 2;
    padding-left: 0rem;
  }

  .servicesPointText {
    color: var(--black);
    font-weight: normal;
    font-size: 1rem;
  }

  #insuranceListContainer {
    display: flex;
    justify-content: center;
  }

  #insuranceList {
    overflow: scroll;
    min-width: 17rem;
    width: 90%;
    border: solid .5px;
    max-height: 30vh;
  }

  .insurancePlan {
    font-size: 1.2rem;
    margin-left: 0rem;
    padding-bottom: 0.8rem;
  }

  .subInsurancePlan {
    font-size: 1.2rem;
    margin-left: 1rem;
    padding-bottom: 0.8rem;
    list-style-type: circle;
  }

  #ourServicesWeight {
    float: right;
    max-width: 20rem;
    margin: 0 3rem 1.5rem 3rem;
  }

  #weightServicesImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 100%;
  }

  #weightLossButton {
    font-size: 1.2rem;
    color: var(--bright-red);
    margin-left: 0.5rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;
  }

  #weightLossButton:hover {
    color: #000000;
  }

  #ourServicesHormone {
    display: block;
    column-count: 1;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    max-width: 75%;
  }

  #servicesWhiteSpace {
    height: 10vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ______________________________ Weight Loss _______________________________ */
  #mainCharacterWeightLoss {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  .weightTitle {
    font-size: var(--h3-text);
    font-weight: bold;
    padding-top: 4.5vh;
  }

  #weightTopSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .beforeAfterTitle {
    font-size: 1.3rem;
  }

  #weightImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 100%;
    margin: 2rem 0;
    border-bottom: solid;
    border-color: #bdbdbd;
    border-width: 0.1vh;
    border-radius: 0.5vw;

  }

  .beforeAfterContainer {
    font-size: var(--p-text);
    margin-right: 1rem;
  }

  .beforeAfterPhoto {
    border-radius: 0.2rem;
    height: 100%;
    box-shadow: 0.2rem 0.3rem 0.9rem black;
  }

  .beforeAfterText {
    font-size: var(--p-text);
    margin: 0.6rem 1rem;
  }

  .weightSubText {
    color: var(--black);
    font-weight: normal;
    font-size: 1.1rem;
    padding-left: 0rem;
  }

  .listItemText {
    font-size: 1.1rem;
    margin-left: 0rem;
    padding-bottom: 0.6rem;
  }

  .weightSubListText {
    font-size: 1.1rem;
    padding-bottom: 0.6rem;
    margin-left: 0.5rem;
    list-style-type: circle;
  }

  .columnList {
    column-count: 2;
  }

  .phoneButton {
    display: block;
    position: fixed;
    bottom: 70px;
    right: 14px;
  }

  #weightWhiteSpace {
    height: 11vh;
  }

  /* -------------------------------------------------------------------------- */

  /* ___________________________ Our Privacy Policy ___________________________ */
  #mainContainerPrivacy {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  #mainContainerPrivacy>h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }

  #mainContainerPrivacy>.top {
    padding-top: 4vh;
  }

  #mainContainerPrivacy>p {
    padding-left: 1rem;
    color: var(--gray);
    font-weight: normal;
    font-size: 1.1rem;
  }

  #mainContainerPrivacy>.policy {
    padding-left: 2rem;
    color: var(--gray);
    font-weight: normal;
    font-size: 1.1rem;
  }

  #mainContainerPrivacy>.address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 1rem 0;
    font-weight: bold;
  }

  #privacyWhiteSpace {
    height: 15vh;
  }

  /* -------------------------------------------------------------------------- */

  /* _______________________________ Contact Us _______________________________ */
  #mainContainerContactUs {
    max-width: 100%;
    margin: auto;
    width: 95%;
  }

  #mapInfoContactUs {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 0.1fr 1fr 1fr;
    grid-template-areas:
      "phone"
      "fax"
      "map"
      "contactField";
    margin: 4vh 0;
  }

  .infoContactUs {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .infoContactUs>.icon {
    color: var(--bright-red);
  }

  .infoContactUs>p {
    font-weight: normal;
    font-size: 1.2rem;
  }

  .phoneInfo {
    grid-area: phone;
  }

  .faxInfo {
    grid-area: fax;
  }

  /* #infoAndPhotoContactUs > img {
  display: none;
  padding: 1rem 0 0 11rem;
  width: 100%;
} */

  #mapInfoContactUs>iframe {
    grid-area: map;
    display: flex;
    justify-self: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: #5A7584;
    box-shadow: 0.1rem 0.1rem #d4d4d4;
    min-width: 90%;
    max-width: 96%;
    height: 90%;
  }

  #contactFieldPlacement {
    grid-area: contactField;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  #profileContainerContactUs {
    display: block;
    column-count: 1;
    padding-top: 1rem;
    max-width: 100%;
  }

  #contactUsProfile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1.3rem;
    width: 100%;
  }

  #contactUsContact {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  #contactUsNames {
    font-size: 1.3rem;
    padding-left: 0.4rem;
    margin-bottom: 0;
    max-width: 100%;
  }

  #contactUsDividerProfile {
    border-style: solid;
    border-width: 1px;
    border-color: #e4e4e4;
    margin: 0.5rem 0 0.5rem 0.1rem;
  }

  #contactUsInfoBtn {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--bright-red);
    padding-left: 0rem;
  }

  #modalContainer {
    width: 90%;
    height: 70%;
  }

  #contactUsInsideInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    width: 100%;
  }

  .contactUsProviderInfo {
    font-size: 1.4rem;
    width: 100%;
    margin-left: 0.1rem;
  }

  .modalGroup {
    margin-bottom: 4rem;
  }

  #mainContainerContactUs>h2 {
    font-size: 1.6rem;
  }

  #contactUsBottomContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  #contactUsSubText {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 0.6rem;
    margin-right: 2rem;
  }

  #contactUsPhoto {
    display: flex;
    align-self: center;
    margin-top: 1.5rem;
    max-width: 75%;
    border-radius: 0.8rem;
    box-shadow: 0.4rem 0.4rem 1.5rem rgb(75, 75, 75);
  }

  #contactUsSubText>p {
    color: #515151;
    font-weight: normal;
    font-size: 1.2rem;
  }

  #contactUsSubText>.directions {
    padding-left: 0.6rem;
  }

  #contactUsWhiteSpace {
    height: 12vh;
  }

  /* -------------------------------------------------------------------------- */

  /* _____________________________ Contact Field _____________________________ */
  #contactFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-style: solid;
    border-color: #cccccc;
    border-width: 0.1rem;
    border-radius: 1rem;
    box-shadow: 0.1rem 0.1rem #cccccc;
  }

  .status {
    display: flex;
    flex-wrap: row wrap;
    justify-content: center;
    font-size: 1rem;
    margin: 1vh 0 2vh 0;
  }

  .contactField {
    margin: 0.2rem 0;
    font-size: 1rem;
  }

  .label {
    margin-right: 0.5rem;
  }

  .input {
    padding: 0.4rem 3rem 0.4rem 0.3rem;
    margin-right: 0.5rem;
    border-radius: 0.3rem;
  }

  .message {
    align-self: center;
    width: 100%;
  }

  .submit {
    align-self: center;
  }

  #submitBtn {
    background-color: var(--off-white);
    color: black;
    border-color: black;
    border-radius: 0.6rem;
    box-shadow: 0.1rem 0.1rem lightgray;
    font-size: 1.1rem;
    padding: 0.3rem 1.5rem;
  }

  .contactFieldGroups {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 100%;
  }

  /* -------------------------------------------------------------------------- */

  /* _________________________________ Footer _________________________________ */
  #footerContainer {
    display: none;
    background-color: #1C1C1C;
    height: 100px;
    width: 100%;
    bottom: 0;
  }

  #footerMainContainer {
    max-width: 1250px;
    margin: auto;
  }

  #footerBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #footerCopyright {
    font-size: 12px;
    padding: 15px 0 0 15px;
    color: #a1a1a1;
  }

  #footerLinkCopyright {
    font-size: 12px;
  }

  #footerNavContainer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
  }

  #footerNav {
    margin-right: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .footerEachNav {
    font-size: 12px;
    color: var(--white);
  }

  /* -------------------------------------------------------------------------- */
  #bottomMenuMainContainer {
    background-color: var(--white);
    box-shadow: 5px 6px 10px rgb(30, 30, 30, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: stretch;
    position: fixed;
    bottom: 0;
    z-index: 5;
    height: 60px;
    width: 100%;
  }

  .iconContainers {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  .iconContainers:not(:last-child) {
    height: 100%;
    border-right: 1px solid #c6c6c6;
  }

  .icons {
    color: var(--black);
  }

  .bottomMenuText {
    align-items: center;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    width: 60px;
    margin-left: -20px;
  }

}